<template>
  <section :class="company">
    <div class="row">
      <!-- Left -->
      <div class="col-sm-12 col-md-7 form__wrapper">
        <div class="container">
          <!-- Header -->
          <header class="row">
            <div class="col sm">
              <h1><strong>Trabaja con nosotros</strong></h1>
              <p>
                Queremos conocerte y que formes parte de algunos de nuestros
                departamentos (ventas, finanzas, logística, marketing, etc.)
                Anímate a formar parte de una empresa dinámica con grandes
                posibilidades de crecimiento y fuertes valores humanos.
              </p>
            </div>
          </header>
          <!-- Form -->
          <div class="row">
            <div class="form-group col-sm-12 col-md-6">
              <label for="first_name">Nombre</label>
              <input
                type="text"
                class="form-control"
                id="first_name"
                v-model="form.first_name"
              />
            </div>
            <div class="form-group col-sm-12 col-md-6">
              <label for="last_name">Apellidos</label>
              <input
                type="text"
                class="form-control"
                id="last_name"
                v-model="form.last_name"
              />
            </div>
            <div class="form-group col-sm-12 col-md-6">
              <label for="email">Email</label>
              <input
                type="email"
                class="form-control"
                id="email"
                v-model="form.email"
              />
            </div>
            <div class="form-group col-sm-12 col-md-6">
              <label for="phone">Teléfono</label>
              <input
                type="tel"
                class="form-control"
                id="phone"
                v-model="form.phone"
              />
            </div>
            <div class="form-group col-sm-12" style="display: none">
              
              <input
                ref="file_ref"
                style="display:none"
                @change="onChangeFile"
                type="file"
                class="form-control-file"
                id="file"
              />
            </div>

            <div class="form-group col-sm-12" @click="callFileInput" style="cursor:pointer">
              <label for="file">Adjunta tu curriculum</label>
              <div class="input-group">

                <input style="cursor:pointer" :value="form.file?.name" type="text" class="form-control" aria-label="Amount (to the nearest dollar)">
                <div class="input-group-append">
                  <span class="input-group-text">Buscar</span>
                </div>
              </div>

            </div>
            
          </div>
          
          <div class="row">
            <div class="col-sm-12">
              <p>
                Sus datos serán utilizados por
                {{
                  company == "salupro"
                    ? "Salupro Spain S.L"
                    : "Logistica Dentalvip S.L"
                }}, en base al consentimiento, para atender y gestionar su
                solicitud de potencial candidato. Sus datos no se cederán a
                terceros, salvo obligación legal. Ud. Tiene derecho, entre
                otros, a acceder, rectificar y suprimir sus datos. Consulte la
                información completa en nuestra
                <a target="new" v-if="company == 'salupro'" href="https://dental.salupro.com/content/1-privacidad-"><strong>Política de privacidad</strong></a>
                <a target="new" v-if="company == 'dentalvip'" href="https://www.dentalvip.es/politica-de-privacidad"><strong>Política de privacidad</strong></a>
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <button
                class="btn btn-primary"
                :disabled="loading || v.$invalid"
                @click="onSubmitForm"
              >
                Aceptar y enviar
                <i
                  class="fa fa-spinner spinner"
                  v-if="loading"
                  aria-hidden="true"
                ></i>
                <i class="fa fa-angle-right" aria-hidden="true" v-else></i>
              </button>

              <div class="alert alert-success" role="alert" v-if="success">
                Se ha enviado tu curriculum correctamente, pronto nos podremos
                en contacto contigo.
              </div>

              <div class="alert alert-danger" role="alert" v-if="error">
                Ha habido un error al intentar enviar sus datos, intentelo de
                nuevo más tarde.
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Right -->
      <div class="col-sm-12 col-md-5 bg">
        <div class="index-video-wrapper">
          <div class="bg-wrapper"></div>
          <video autoplay loop id="index-video" muted>
          <source src="./assets/video.webm" type="video/webm">
          </video>
        </div>
        <div class="logo">
          <img
            v-if="company == 'salupro'"
            src="./assets/salupro.png"
            alt="logo salupro"
          />
          <img
            v-if="company == 'dentalvip'"
            src="./assets/dentalvip.png"
            alt="logo dentalvip"
          />
          <h3>
            Las oportunidades están en todas partes. <br />
            Y en <span style="text-transform: capitalize;">{{ company }}</span>, te ayudaremos a encontrarlas.
          </h3>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { reactive, ref, onMounted } from "vue";

const phoneValidate = (value) => {
  const str = value.toString().replace(/\s/g, "");
  return str.length === 9 && /^[679]{1}[0-9]{8}$/.test(str);
};
export default {
  name: "App",
  setup() {
    const file_ref = ref(null)
    onMounted( () => {
      console.log(file_ref.value)
    })
    
    const company = ref('salupro'); 
    if(location.host.includes('salupro')) company.value = 'salupro'
    else if(location.host.includes('dentalvip')) company.value = 'dentalvip'

    const success = ref(false);
    const error = ref(false);
    const loading = ref(false);
    const form = reactive({
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      lopd: false,
      file: null,
    });
    const rules = {
      first_name: { required },
      last_name: { required },
      email: { required, email },
      phone: { required, phoneValidate },
      file: {
        required(val) {
          return val;
        },
      },
    };
    const v = useVuelidate(rules, form);


    const callFileInput = () => {
      file_ref.value.click();
    }

    const onChangeFile = (e) => {
      const file = e.target.files[0];
      form.file = file;
    };
    const onSubmitForm = () => {
      loading.value = true;
      success.value = false;
      error.value = false;

      const formData = new FormData();
      formData.append("file", form.file);
      formData.append("first_name", form.first_name);
      formData.append("last_name", form.last_name);
      formData.append("email", form.email);
      formData.append("phone", form.phone);
      formData.append("company", company.value );

      const options = {
        method: "POST",
        body: formData,
      };
      fetch(
        "https://hooks.zapier.com/hooks/catch/2222244/ojfri9v",
        options
      ).then((response) => {
        loading.value = false;
        if (response.status == 200) {
          success.value = true;
        } else {
          error.value = true;
        }
      });
    };

    return {
      v,
      onSubmitForm,
      onChangeFile,
      callFileInput,
      form,
      loading,
      company,
      success,
      error,
      file_ref
    };
  },
};
</script>

<style>
  #index-video {
    
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: -100;
    right: 0;
    bottom: 0;
    background-size: cover;
    overflow: hidden;
}

.index-video-wrapper {
  position: absolute;
    left: 0;
    top:0;
    height: 100%;
    width: 100%; 
    background-size: cover;
}
.index-video-wrapper .bg-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0, .3);
  z-index: 1;
}
</style>

<style lang="scss">

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
  @media only screen and (max-width: 600px) {
    overflow: auto;
    padding: 3rem 0;
  }
  overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  p {
    font-size: 1rem;
    color: #999999;
    line-height: 22px;
    text-align: justify;
    -moz-text-align-last: left;
    text-align-last: left;
    a {
      color: #999999;
      &:hover { color: black }
    }
  }
  .alert {
    margin-top: 1rem;
  }
  .btn {
    @keyframes spinner {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
    .spinner {
      animation: spinner 1.5s linear infinite;
    }
    
    padding: 7px 2rem;
    border-radius: 0;
    font-size: 14px;
    font-weight: bold;
    border: none;
    .fa {
      margin-left: 10px;
      font-weight: bold;
    }
  }
}

.dentalvip {
  $color: #e37c2b;
  .btn-primary {
    background: $color;
    color: white;
    &:hover {
      background: rgba($color, 0.9);
    }
    &:disabled {
      background: rgba($color, .6);
      cursor: not-allowed;
    }
  }
  .form-control:focus {
    border-color: $color;
    box-shadow: none;
  }
}
.salupro {
  $color: #04599c;
  .btn-primary {
    background: $color;
    color: white;
    &:hover {
      background: rgba($color, 0.9);
    }
    &:disabled {
      background: rgba($color, .6);
      cursor: not-allowed;
    }
  }
  .form-control:focus {
    border-color: $color;
    box-shadow: none;
  }
}

.container {
  max-width: 75% !important;
}
.form__wrapper {
  @media (-webkit-device-pixel-ratio: 1.25) {
    zoom: 0.8;
  }
  @media (-webkit-device-pixel-ratio: 1.5) {
    zoom: 0.8;
  }
  @media only screen and (max-width: 600px){
    min-height: initial;
  }
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.bg {
  @media only screen and (max-width: 600px) {
    display: none;
  }
  min-height: 100vh;
  background-image: url("./assets/bg.jpg");
  background-size: cover;
  background-position: center;
  position: relative;
  .logo {
    z-index: 2;
    position: absolute;
    bottom: 4rem;
    right: 2rem;
    text-align: right;
    color: white;
    img {
      width: 200px;
      margin-bottom: .5rem;
    }
    h3 {
      font-size: 18px;
      font-weight: 600;
    }
  }
}
</style>
