import { createApp } from 'vue'
import App from './App.vue'

// import '../node_modules/materialize-css/dist/css/materialize.min.css';
// import '../node_modules/materialize-css/dist/js/materialize.min.js';

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/font-awesome/scss/font-awesome.scss';
//import '../node_modules/bootstrap/dist/js/bootstrap.min.js';


createApp(App).mount('#app')
